let loadingRef: any;
export const setLoadingRef = (ref: any) => {
  loadingRef = ref;
};

export const LoadingUtil = {
  show(text: string = "") {
    loadingRef.change(true, text);
  },
  hide() {
    loadingRef.change(false, "");
  },
};
