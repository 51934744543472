/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-02 18:42:20
 * @LastEditors: leif
 * @LastEditTime: 2023-03-03 11:07:59
 */
import {NavigationProp} from '@react-navigation/native';
import React from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {pxToDp} from '../utils';
import {WebView} from 'react-native-webview';

type PrivacyScreenProps = {
  navigation: NavigationProp<any>;
};
const PrivacyScreen: React.FC<PrivacyScreenProps> = ({navigation}) => {
  return (
    <View
      style={{
        backgroundColor: '#FE8528',
        flex: 1,
        flexDirection: 'column',
        paddingTop: StatusBar.currentHeight,
      }}>
      <View
        style={{
          padding: pxToDp(30),
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack();
          }}
          style={{
            paddingVertical: pxToDp(20),
          }}>
          <Image
            source={require('../assets/page-back.png')}
            style={{
              width: pxToDp(35),
              height: pxToDp(29),
            }}
          />
        </TouchableOpacity>
        <Text
          style={{
            textAlign: 'center',
            flex: 1,
            color: '#fff',
            fontSize: pxToDp(40),
            fontWeight: 'bold',
          }}>
          Privacy Policy
        </Text>
        <View
          style={{
            width: pxToDp(35),
            height: pxToDp(29),
            paddingVertical: pxToDp(20),
          }}></View>
      </View>
      <View
        style={{
          flex: 1,
          backgroundColor: '#fff',
          marginHorizontal: pxToDp(30),
          borderRadius: pxToDp(30),
          marginBottom: pxToDp(30),
        }}>
        <WebView
          source={{uri: 'https://www.baidu.com/'}}
          onShouldStartLoadWithRequest={request => {
            Linking.openURL(request.url);
            return false;
          }}
        />
        <iframe style={{'height':'100%'}} src="https://www.baidu.com/"></iframe>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({});
export default PrivacyScreen;
