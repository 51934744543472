import React, {useEffect} from 'react';
import {Image, StyleSheet, Text} from 'react-native';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
interface SplashScreenProps {
  navigation: NavigationProp<any>;
}
const SplashScreen: React.FC<SplashScreenProps> = ({navigation}) => {
  useFocusEffect(() => {
    setTimeout(() => {
      navigation.reset({
        index: 0,
        routes: [{name: 'HomeScreen'}],
      });
    }, 3000);
  });
  return (
    <Image
      source={require('../assets/splash.png')}
      style={{width: '100%', height: '100%'}}></Image>
  );
};
const styles = StyleSheet.create({});
export default SplashScreen;
