/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-02 18:42:20
 * @LastEditors: leif
 * @LastEditTime: 2023-03-16 16:21:56
 */
import {NavigationProp} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {pxToDp} from '../utils';
import {WebView} from 'react-native-webview';
import {DetailInterface} from '../utils/interface';
import {apkCount} from '../api';
import Storage from '../utils/Storage';
type DetailScreenProps = {
  navigation: NavigationProp<any>;
  route: any;
};
const DetailScreen: React.FC<DetailScreenProps> = ({navigation, route}) => {
  const INJECTEDJAVASCRIPT = `

(function () {
  function addMeta() {
    const meta = document.createElement('meta'); 
    meta.setAttribute('content', 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'); 
    meta.setAttribute('name', 'viewport'); 
    document.getElementsByTagName('head')[0].appendChild(meta); 
  }
  setInterval(addMeta, 100);
}());

  
`;

  const [obj, setObj] = useState({} as DetailInterface);
  useEffect(() => {
    setObj(route.params.obj || {});
  }, [route.params.obj]);
  return (
    <View
      style={{
        backgroundColor: '#FE8528',
        flex: 1,
        flexDirection: 'column',
        paddingTop: StatusBar.currentHeight,
      }}>
      <View
        style={{
          padding: pxToDp(30),
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack();
          }}
          style={{
            paddingVertical: pxToDp(20),
          }}>
          <Image
            source={require('../assets/page-back.png')}
            style={{
              width: pxToDp(35),
              height: pxToDp(29),
            }}
          />
        </TouchableOpacity>
        <Text
          style={{
            textAlign: 'center',
            flex: 1,
            color: '#fff',
            fontSize: pxToDp(40),
            fontWeight: 'bold',
          }}>
          {obj['appName']}
        </Text>
        <View
          style={{
            width: pxToDp(35),
            height: pxToDp(29),
            paddingVertical: pxToDp(20),
          }}></View>
      </View>
      <View
        style={{
          flex: 1,
          backgroundColor: '#fff',
          marginHorizontal: pxToDp(30),
          borderRadius: pxToDp(30),
          marginBottom: pxToDp(30),
          overflow: 'hidden',
          padding: pxToDp(10),
          alignItems: 'center',
        }}>
        {obj['logo'] ? (
          <Image
            source={{uri: obj['logo']}}
            style={{
              width: pxToDp(152),
              height: pxToDp(152),
              borderRadius: pxToDp(152 / 2),
              borderWidth: pxToDp(1),
              borderColor: '#FE8528',
              resizeMode: 'contain',
              marginTop: pxToDp(40),
            }}></Image>
        ) : null}
        {obj['banner'] ? (
          <View style={{width: '100%', padding: pxToDp(40)}}>
            <Image
              source={{uri: obj['banner']}}
              style={{
                height: pxToDp(288),
                borderRadius: pxToDp(20),
                borderWidth: pxToDp(1),
                borderColor: '#FE8528',
              }}></Image>
          </View>
        ) : null}
        <View style={{width: '100%', paddingHorizontal: pxToDp(40), flex: 1}}>
          <div dangerouslySetInnerHTML={{__html:obj['content']}} style={{overflow:"auto"}}></div>
          {/* <WebView
            style={{width: '100%'}}
            originWhitelist={['*']}
            directionalLockEnabled={false}
            setSupportMultipleWindows={false}
            injectedJavaScript={INJECTEDJAVASCRIPT}
            source={{
              html: obj['content'],
            }}
            onShouldStartLoadWithRequest={request => {
              (async () => {
                let phone = await Storage.getItem('phone');
                let result = await apkCount({
                  phone,
                  appName: obj['appName'],
                  type: 'DOWNLOADBTNCLICK',
                });
                console.log(result);
                if (result.response.cont.isDownloadBtnFirstClick) {
                  // Click for the first click
                }
              })();

              Linking.openURL(request.url);
              return false;
            }}
          /> */}
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({});
export default DetailScreen;
