/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-02-20 21:10:57
 * @LastEditors: leif
 * @LastEditTime: 2023-03-16 13:44:38
 */
import React, { useEffect, useRef, forwardRef } from "react";
import { StyleSheet, Text, StatusBar } from "react-native";
import { Adjust, AdjustEvent, AdjustConfig } from "react-native-adjust";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeScreen from "./src/screens/HomeScreen";
import SplashScreen from "./src/screens/SplashScreen";
import DetailScreen from "./src/screens/DetailScreen";
import LoginScreen from "./src/screens/LoginScreen";
import PrivacyScreen from "./src/screens/PrivacyScreen";
import Loading from "./src/components/Loading";
const L = forwardRef(Loading);
const Stack = createNativeStackNavigator();
import { setLoadingRef } from "./src/utils/LoadingUtil";
import Storage from "./src/utils/Storage";
interface AppProps {}
const App: React.FC<AppProps> = () => {
  interface LoadingVoid {
    change: (loading: boolean, text: string) => void;
  }
  const loadingRef = useRef<LoadingVoid>();
  // const adjustConfig = new AdjustConfig(
  //   'km7zibgww3y8',
  //   AdjustConfig.EnvironmentSandbox,
  // );
  // adjustConfig.setLogLevel(AdjustConfig.LogLevelVerbose); // enable all logging
  // adjustConfig.setAttributionCallbackListener(function (attribution) {
  //   // Printing all attribution properties.
  //   Storage.setItem('channel', attribution.network);
  //   console.log('Attribution changed!');
  //   console.log(attribution.trackerToken);
  //   console.log(attribution.trackerName);
  //   console.log(attribution.network);
  //   console.log(attribution.campaign);
  //   console.log(attribution.adgroup);
  //   console.log(attribution.creative);
  //   console.log(attribution.clickLabel);
  //   console.log(attribution.adid);
  //   console.log(attribution.costType);
  //   console.log(attribution.costAmount);
  //   console.log(attribution.costCurrency);
  //   console.log(attribution.fbInstallReferrer);
  // });
  // Adjust.create(adjustConfig);
  // var adjustEvent = new AdjustEvent('wj7m8l');
  // Adjust.trackEvent(adjustEvent);
  const linking = {
    prefixes: [
      /* your linking prefixes */
    ],
    screens: {
    Web:{
      screens:{
        HomeScreen: 'HomeScreen',
        SplashScreen: 'SplashScreen',
        DetailScreen: 'DetailScreen',
        LoginScreen: 'LoginScreen',
        PrivacyScreen: 'PrivacyScreen',
      }
    }
    },
  };
  
  return (
    <NavigationContainer linking={linking} ref={(ref) => {}}>
      <StatusBar
        translucent={true}
        backgroundColor="transparent"
        barStyle={"dark-content"}
      />
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="SplashScreen" component={SplashScreen} />
        <Stack.Screen name="HomeScreen" component={HomeScreen} />
        <Stack.Screen name="DetailScreen" component={DetailScreen} />
        <Stack.Screen name="LoginScreen" component={LoginScreen} />
        <Stack.Screen name="PrivacyScreen" component={PrivacyScreen} />
      </Stack.Navigator>
      <L ref={setLoadingRef}></L>
    </NavigationContainer>
  );
};
const styles = StyleSheet.create({});
export default App;
