/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-02-27 14:25:23
 * @LastEditors: leif
 * @LastEditTime: 2023-02-27 20:32:52
 */
import request from '../utils/request';
export const apkList = (data?: any) => {
  return request.post({
    url: '/v2/loanapi/apk/list',
    data,
  });
};
export const userLogin = (data?: any) => {
  return request.post({
    url: '/v2/loanapi/apk/user/login',
    data,
  });
};
export const apkCount = (data?: any) => {
  return request.post({
    url: '/v2/loanapi/apk/apk_count',
    headers: {hideLoading: true},
    data,
  });
};
