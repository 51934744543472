/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-01 20:30:33
 * @LastEditors: leif
 * @LastEditTime: 2023-03-02 11:18:35
 */
import {Dimensions, PixelRatio} from 'react-native';

export const pxToDp = (
  uiElementPx: string | number,
  isInt = true,
  uiWidth = 720,
) => {
  const deviceWidthDp = Dimensions.get('window').width;
  if (typeof uiElementPx === 'string') {
    uiElementPx = parseFloat(uiElementPx);
  }
  if (uiElementPx <= 3) {
    return uiElementPx;
  }
  const mathResult = (uiElementPx * deviceWidthDp) / uiWidth;
  const resultInt = isInt ? Math.ceil(mathResult) : mathResult;
  return mathResult > 1 / PixelRatio.get() ? resultInt : 1 / PixelRatio.get();
};
// Random string
export const randomString = (length = 10) => {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};
