/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-15 20:09:13
 * @LastEditors: leif
 * @LastEditTime: 2023-03-16 11:17:27
 */
import React, {useState, useEffect, useImperativeHandle} from 'react';
import {View, StyleSheet, ActivityIndicator, Modal, Text} from 'react-native';
import {pxToDp} from '../utils';
const Loading = (props:any,ref: React.Ref<unknown> | undefined) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const change = (l: boolean, t: string = '') => {
    console.log(l, t);
    setLoading(l);
    setText(t);
  };
  useImperativeHandle(ref, () => ({
    change: change,
  }));
  // useEffect(() => {
  //   props.text && setText(props.text);
  //   return () => {};
  // }, []);
  return (
    <>
      {loading ? (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(178,178,178,0.8)',
            zIndex: new Date().getTime(),
          }}>
          <Modal
            animationType="fade"
            visible={loading}
            transparent={true}
            style={{flex: 1}}>
            <View style={styles.container}>
              <View style={[styles.load_box]}>
                <ActivityIndicator
                  animating={true}
                  size={'large'}
                  style={styles.load_progress}
                />
                {text ? <Text style={[styles.load_text]}>{text}</Text> : null}
              </View>
            </View>
          </Modal>
        </View>
      ) : null}
    </>
  );
};
const styles = StyleSheet.create({
  load_box: {
    padding: pxToDp(80),
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: pxToDp(30),
  },
  load_progress: {
    width: pxToDp(50),
    height: pxToDp(50),
  },
  load_text: {
    color: '#FFF',
    backgroundColor: '#000',
    marginTop: pxToDp(30),
    maxWidth: '50%',
    fontSize: pxToDp(30),
    textAlign: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default Loading;
