/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-01 19:03:31
 * @LastEditors: leif
 * @LastEditTime: 2023-03-16 14:07:56
 */
import axios, { type AxiosResponse } from "axios";
import { randomString } from "./index";
import { Md5 } from "ts-md5";
import QS from "qs";
import type {
  AxiosInstance,
  InternalAxiosRequestConfig,
  CreateAxiosDefaults,
  AxiosRequestConfig,
} from "axios";
import { ToastAndroid, Platform } from "react-native";
import DeviceInfo from "react-native-device-info";
import Storage from "./Storage";
import { LoadingUtil } from "./LoadingUtil";

class Request {
  // axios Instance
  instance: AxiosInstance;
  // * Storage cancel requested controller MAPel requested controller MAP
  abortControllerMap: Map<string, AbortController>;
  requestNum: number;
  constructor(config: CreateAxiosDefaults) {
    this.instance = axios.create(config);
    // * Initialize Story Cancel the Request Control MAP
    this.abortControllerMap = new Map();
    this.requestNum = 0;
    // Interceptor execution sequence interface request-> Example request -> global request -> Enterprise response-> global response-> interface response
    this.instance.interceptors.request.use(
      async (res: InternalAxiosRequestConfig) => {
        const url = res.url || "";
        if (!res.headers.hideLoading) {
          this.requestNum++;
          // LoadingUtil.show('Loading');
          const controller = new AbortController();
          res.signal = controller.signal;
          if (this.abortControllerMap.get(url)) {
            this.abortControllerMap.get(url)?.abort();
          }
          this.abortControllerMap.set(url, controller);
        }
        console.log(res);
        res.data = res.data || {};
        res.data.noncestr = randomString(13);
        res.data.sign = Md5.hashStr(
          Object.entries(res.data)
            .filter((item) => item[1])
            .map((item) => item.join("="))
            .sort()
            .join("&") + "&indiakey=6ShEUmiNSp9sQWgBzS8N831zyJXlKEKrjqlcZBZN"
        ).toLocaleUpperCase();
        interface DeviceInfo {
          channel: unknown;
          appVersion: string;
          bag: string;
          brand: string;
          model: string;
          operationSys: string;
          osVersion: string;
        }
        let deviceInfo = {} as DeviceInfo;
        if (Platform.OS == "android") {
          res.headers.set("bag", DeviceInfo.getBundleId());
          deviceInfo.appVersion = DeviceInfo.getVersion();
          deviceInfo.bag = DeviceInfo.getBundleId();
          deviceInfo.brand = DeviceInfo.getBrand();
          deviceInfo.model = DeviceInfo.getModel();
          deviceInfo.operationSys = DeviceInfo.getSystemName();
          deviceInfo.osVersion = DeviceInfo.getSystemVersion();
        } else {
          res.headers.set("bag", "com.my.aadhar.loan.guide.app");
          deviceInfo.bag = "com.my.aadhar.loan.guide.app";
        }
        let token;
        try {
          token = await Storage.getItem("token");
        } catch (error) {}
        res.headers.set("token", token as string);
        deviceInfo.channel = "Organic";
        try {
          deviceInfo.channel = (await Storage.getItem("channel")) || "Organic";
        } catch (error) {}
        res.headers.set("deviceInfo", JSON.stringify(deviceInfo));
        res.data = QS.stringify(res.data);
        console.log(res);
        return res;
      },
      (err: any) => {
        if (!err.headers.hideLoading) {
          this.requestNum--;
          if (this.requestNum <= 0) {
            LoadingUtil.hide();
          }
        }
        Promise.reject(new Error("Error"));
      }
    );

    // Global response interceptor guarantee the final execution
    this.instance.interceptors.response.use(
      // Because the data of our interface is under res.data, we will return directly to Res.Data
      (res: AxiosResponse) => {
        if (!res.headers.hideLoading) {
          this.requestNum--;
          if (this.requestNum <= 0) {
            LoadingUtil.hide();
          }
        }
        console.log("response");
        console.log(res);
        const url = res.config.url || "";
        this.abortControllerMap.delete(url);
        if (res.data.code != 1) {
          ToastAndroid.show(res.data.msg, ToastAndroid.LONG);
          return Promise.reject(res.data.msg || "Error");
        }
        return res.data;
      },
      (err: any) => {
        if (!err.headers.hideLoading) {
          this.requestNum--;
          if (this.requestNum <= 0) {
            LoadingUtil.hide();
          }
        }
        Promise.reject(new Error("Error"));
      }
    );
  }
  request<T>(config: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance
        .request<any, T>(config)
        .then((res: T | PromiseLike<T>) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  /**
   * post
   */
  post<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: "POST" });
  }
  /**
   * Cancel all requests
   */
  cancelAllRequest() {
    console.log(this.abortControllerMap);
    for (const [, controller] of this.abortControllerMap) {
      console.log(controller);
      controller.abort();
    }
    this.abortControllerMap.clear();
  }
  /**
   * Cancel the specified request
   * @param url The request to be canceled URL
   */
  cancelRequest(url: string | string[]) {
    const urlList = Array.isArray(url) ? url : [url];
    for (const _url of urlList) {
      this.abortControllerMap.get(_url)?.abort();
      this.abortControllerMap.delete(_url);
    }
  }
}

let request = new Request({
  baseURL: "http://8.215.46.156:1060",
  timeout: 1000 * 10 * 1,
});
export default request;
//  Cancel request
export const cancelRequest = (url: string | string[]) => {
  return request.cancelRequest(url);
};
// Cancel all requests
export const cancelAllRequest = () => {
  return request.cancelAllRequest();
};
