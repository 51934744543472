import AsyncStorage from '@react-native-async-storage/async-storage';
export default {
  getItem(key: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const value = await AsyncStorage.getItem(key);
        resolve(value);
      } catch (e) {
        resolve('');
      }
    });
  },

  async setItem(key: string, value: any) {
    return new Promise(async (resolve, reject) => {
      try {
        await AsyncStorage.setItem(key, value);
        resolve('');
      } catch (e) {
        reject();
      }
    });
  },
};
