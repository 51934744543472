/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-02 16:13:47
 * @LastEditors: leif
 * @LastEditTime: 2023-03-15 19:18:33
 */
import { NavigationProp } from "@react-navigation/native";
import React, { useState } from "react";
import {
  Image,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  TextInput,
} from "react-native";
import { userLogin } from "../api";
import { pxToDp } from "../utils";
import Storage from "../utils/Storage";
type LoginScreenProps = {
  navigation: NavigationProp<any>;
};
const LoginScreen: React.FC<LoginScreenProps> = ({ navigation }) => {
  const [radio, setRaio] = useState(true);
  const [form, setForm] = useState({
    phone: "",
    code: "",
  });

  return (
    <View
      style={{
        backgroundColor: "#FE8528",
        flex: 1,
        flexDirection: "column",
        paddingTop: StatusBar.currentHeight,
      }}
    >
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            padding: pxToDp(30),
            alignItems: "center",
          }}
        >
          <Image
            source={require("../assets/login-banner.png")}
            style={{ width: pxToDp(467), height: pxToDp(399) }}
          ></Image>
          <ImageBackground
            resizeMode="stretch"
            source={require("../assets/login-content.png")}
            style={{
              width: pxToDp(620),
              paddingTop: pxToDp(100),
              marginTop: pxToDp(-10),
            }}
          >
            <Text
              style={{
                color: "#FE8528",
                fontSize: pxToDp(60),
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              login in
            </Text>
            <View
              style={[styles.flexRow, styles.inputBox, { padding: pxToDp(30) }]}
            >
              <Text
                style={{
                  paddingHorizontal: pxToDp(10),
                  fontSize: pxToDp(40),
                  color: "#333",
                }}
              >
                +91
              </Text>
              <TextInput
                placeholder="Mobile number"
                keyboardType="phone-pad"
                maxLength={10}
                value={form.phone}
                onChangeText={(text) => {
                  setForm({ ...form, phone: text });
                }}
                style={styles.texinput}
              ></TextInput>
            </View>
            <View style={[styles.flexRow, styles.inputBox]}>
              <TextInput
                placeholder="OTP"
                keyboardType="phone-pad"
                value={form.code}
                onChangeText={(text) => {
                  setForm({ ...form, code: text });
                }}
                style={[styles.texinput, { paddingLeft: pxToDp(30) }]}
              ></TextInput>
              <Text
                style={{
                  fontSize: pxToDp(40),
                  backgroundColor: "#FE8528",
                  color: "#fff",
                  padding: pxToDp(30),
                }}
              >
                get OTP
              </Text>
            </View>
            <Text
              onPress={async () => {
                try {
                  let result = await userLogin({
                    phone: form.phone,
                    code: form.code,
                  });
                  Storage.setItem("token", result.response.cont.token);
                  Storage.setItem("phone", form.phone);
                  navigation.navigate("HomeScreen");
                } catch (error) {}
              }}
              style={{
                backgroundColor: "#FE8528",
                color: "#fff",
                fontSize: pxToDp(36),
                textAlign: "center",
                marginHorizontal: pxToDp(100),
                padding: pxToDp(30),
                marginTop: pxToDp(50),
                borderRadius: pxToDp(10),
                fontWeight: "bold",
              }}
            >
              Login now
            </Text>
            <View style={[styles.flexRow, { margin: pxToDp(30) }]}>
              <TouchableOpacity onPress={() => setRaio(!radio)}>
                <Image
                  source={
                    radio
                      ? require("../assets/radio-selected.png")
                      : require("../assets/radio.png")
                  }
                  style={{ width: pxToDp(44), height: pxToDp(44) }}
                ></Image>
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: pxToDp(28),
                  color: "#333",
                  fontWeight: "400",
                  paddingLeft: pxToDp(20),
                  flex: 1,
                }}
              >
                By continuing you agree our{" "}
                <Text
                  style={{ color: "#FE8528" }}
                  onPress={() => {
                    navigation.navigate("PrivacyScreen");
                  }}
                >
                  Terms&Conditions
                </Text>{" "}
                and{" "}
                <Text
                  style={{ color: "#FE8528" }}
                  onPress={() => {
                    navigation.navigate("PrivacyScreen");
                  }}
                >
                  Privacy Policy
                </Text>
              </Text>
            </View>
          </ImageBackground>
        </View>
      </ScrollView>
      <TouchableOpacity
        onPress={() => {
          navigation.goBack();
        }}
        style={{
          position: "absolute",
          top: (StatusBar.currentHeight || 0) + pxToDp(30),
          left: pxToDp(30),
          paddingVertical: pxToDp(20),
        }}
      >
        <Image
          source={require("../assets/page-back.png")}
          style={{
            width: pxToDp(35),
            height: pxToDp(29),
          }}
        />
      </TouchableOpacity>
    </View>
  );
};
const styles = StyleSheet.create({
  texinput: {
    padding: 0,
    fontSize: pxToDp(40),
    overflow: "hidden",
    flex: 1,
  },
  inputBox: {
    backgroundColor: "#fff",
    borderRadius: pxToDp(10),
    marginHorizontal: pxToDp(50),
    marginTop: pxToDp(50),
    overflow: "hidden",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
});
export default LoginScreen;
