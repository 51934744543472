/*
 * @Descripttion:
 * @version:
 * @Author: leif
 * @Date: 2023-03-01 18:48:38
 * @LastEditors: leif
 * @LastEditTime: 2023-03-15 19:54:38
 */
import { NavigationProp, useFocusEffect } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  StatusBar,
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  Linking,
  RefreshControl,
} from "react-native";
import { pxToDp } from "../utils";
import { apkCount, apkList } from "../api";
import Storage from "../utils/Storage";
import { LoadingUtil } from "../utils/LoadingUtil";
type HomeScreenProps = {
  navigation: NavigationProp<any>;
};

const HomeScreen: React.FC<HomeScreenProps> = ({ navigation }) => {
  const [list, setList] = useState([]);
  const [cont, setCont] = useState("");
  const [phone, setPhone] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(true);

  const getList = async () => {
    LoadingUtil.show();
    getPhone();
    try {
      let result = await apkList();
      setList(result.response.list);
      setCont(result.response.cont);
    } catch (error) {}
    setIsRefreshing(false);
  };
  const getPhone = async () => {
    let result = await Storage.getItem("phone");
    setPhone(result as string);
  };
  useEffect(() => {
    navigation.addListener("focus", () => {
      getList();
    });
    return () => {
      navigation.removeListener("focus", () => {
        getList();
      });
    };
  }, []);
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        paddingTop: StatusBar.currentHeight,
        backgroundColor: "#E9E5DC",
      }}
    >
      <View
        style={{
          padding: pxToDp(32),
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          style={{ color: "#333", fontSize: pxToDp(36), fontWeight: "500" }}
        >
          My Aadhar Loan Guide
        </Text>
        {phone ? (
          <Text
            onPress={async () => {
              await Storage.setItem("token", "");
              await Storage.setItem("phone", "");
              getPhone();
            }}
            style={{
              color: "#333",
              borderWidth: pxToDp(1),
              borderColor: "#333",
              borderRadius: pxToDp(10),
              paddingHorizontal: pxToDp(19),
              paddingVertical: pxToDp(9),
              fontSize: pxToDp(30),
            }}
          >
            Logout
          </Text>
        ) : null}
      </View>
      <ScrollView
        style={{ flex: 1 }}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={() => getList()}
            colors={["red", "green", "blue"]}
            title="Loading..."
          />
        }
      >
        <View style={{ paddingHorizontal: pxToDp(30) }}>
          <Image
            resizeMode="stretch"
            source={require("../assets/home.png")}
            style={{
              width: "100%",
              height: pxToDp(372),
              borderRadius: pxToDp(10),
            }}
          ></Image>
          <View
            style={{
              marginHorizontal: pxToDp(20),
              backgroundColor: "#fff",
              borderRadius: pxToDp(10),
              padding: pxToDp(25),
              marginTop: pxToDp(-20),
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                cont && Linking.openURL(cont);
              }}
              style={{ alignItems: "center", flex: 1 }}
            >
              <Image
                style={{ width: pxToDp(80), height: pxToDp(80) }}
                source={require("../assets/rate-app.png")}
              ></Image>
              <Text
                style={{
                  color: "#333",
                  fontSize: pxToDp(28),
                  marginTop: pxToDp(18),
                }}
              >
                RATE APP
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ alignItems: "center", flex: 1 }}
              onPress={() => {
                navigation.navigate("PrivacyScreen");
              }}
            >
              <Image
                style={{ width: pxToDp(80), height: pxToDp(80) }}
                source={require("../assets/privacy.png")}
              ></Image>
              <Text
                style={{
                  color: "#333",
                  fontSize: pxToDp(28),
                  marginTop: pxToDp(18),
                }}
              >
                PRIVACY POLICY
              </Text>
            </TouchableOpacity>
          </View>
          {list && list.length > 0 ? (
            <View
              style={{
                backgroundColor: "#fff",
                borderRadius: pxToDp(10),
                marginVertical: pxToDp(30),
                alignItems: "center",
                paddingVertical: pxToDp(24),
              }}
            >
              {list.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    if (phone) {
                      (async () => {
                        try {
                          let result = await apkCount({
                            phone,
                            appName: item["appName"],
                            type: "PRODUCTLISTCLICK",
                          });
                          if (result.response.cont.isProductListFirstClick) {
                            // Click for the first click
                          }
                        } catch (error) {}
                      })();
                      navigation.navigate("DetailScreen", {
                        obj: item,
                      });
                    } else {
                      navigation.navigate("LoginScreen");
                    }
                  }}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: pxToDp(30),
                    width: "100%",
                  }}
                >
                  <Image
                    source={{
                      uri: item["logo"],
                    }}
                    style={{
                      width: pxToDp(100),
                      height: pxToDp(100),
                      borderWidth: pxToDp(1),
                      borderColor: "#FC862C",
                      borderRadius: pxToDp(50),
                    }}
                  ></Image>
                  <View
                    style={{
                      flex: 1,
                      marginLeft: pxToDp(30),
                      minHeight: pxToDp(150),
                      borderBottomColor: "#E5E5E5",
                      borderBottomWidth: pxToDp(1),
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: "#333",
                        fontSize: pxToDp(40),
                        fontWeight: "bold",
                      }}
                    >
                      {item["appName"]}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          ) : null}
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({});
export default HomeScreen;
